.inventory {
  --max-width: 1400px;

  display: flex;
  flex-direction: column;
  background-color: #121429;
  isolation: isolate;
  min-height: 100vh;
}

.header__wrapper {
  display: flex;
  justify-content: center;
}

.inventory__header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--max-width);
  width: 100%;
}

.inventory__header img {
  width: 20px;
}

.inventory__headerLeft {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.inventory__user {
  border: 2px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  font-size: 18px;
  position: relative;
  color: white;
  cursor: pointer;
}

.inventory__headerBack {
  color: white;
  cursor: pointer;
  font-size: 18px;
}

.inventory__topWrapper {
  display: flex;
  justify-content: center;
  background: linear-gradient(to right, #4ca2af, #121429);
  z-index: -1;
}

.inventory__top {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0.5rem;
  gap: 0.5rem;
  width: 100%;
  max-width: var(--max-width);
}

.inventory__topUp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.inventory__topUp > h1 {
  font-size: 22px;
  font-weight: 600;
}

.inventory__topUp > span {
  font-size: 20px;
  font-weight: 300;
}

.inventory__topDown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.inventory__id {
  font-weight: 300;
  font-size: 14px;
}

.inventory__buy {
  font-weight: 300;
  text-decoration: underline;
}

.inventory__centerWrapper {
  display: flex;
  justify-content: center;
  flex: 1;
}

.inventory__center {
  width: 100%;
  padding: 1rem 1rem 0 1rem;
  display: flex;
  position: relative;
  justify-content: center;
}

.inventory__centerTop {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.inventory__centerHeading {
  font-size: 20px;
  font-weight: 400;
  min-width: 131px;
  color: white;
}

.inventory__centerSubHeading {
  font-size: clamp(12px, 3.5vw, 14px);
  color: white;
  transform: translateY(1px);
}

.inventory__empty {
  font-size: 22px;
  padding-block: 5rem;
  color: white;
  text-align: center;
}

.inventory__footerWrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.inventory__footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.inventory__footer > a {
  font-size: 10px;
  text-align: center;
  color: #4ca2af;
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  gap: 0.25rem;
  align-items: center;
  height: 60px;
  padding: 10px;
  width: 100%;
  transition: all 0.1s;
}

.inventory__footer > a > svg {
  font-size: 18px;
}

.inventory__selected {
  background-color: #4ca2af;
  color: white !important;
  text-decoration: none !important;
}

.inventory__centerRight {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--max-width);
}

.inventory__centerLeft {
  display: none;
  flex-direction: column;
  position: absolute;
  left: 0;
  width: 15vw;
  max-width: 200px;
  background-color: #121429;
  border-radius: 0 36px 36px 0;
  height: calc(100% - 1rem);
}

.inventory__centerLeft > a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  color: white;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s;
  font-size: 20px;
  border: 2px solid #4ca2af;
  box-shadow: 0 0.125rem 0.5625rem 0 #4ca2af;
}

.inventory__centerLeft > a:nth-child(1) {
  border-radius: 0 36px 0 0;
}

.inventory__centerLeft > a:nth-last-child(1) {
  border-radius: 0 0 36px 0;
}

.inventory__centerLeft span {
  font-size: 14px;
  cursor: pointer;
}

.inventory__centerLeft > a:hover,
.inventory__footer > a:hover {
  background-color: #5e0f90;
  color: white !important;
}

.inventory__selected:hover {
  background-color: #4ca2af !important;
}

@media (min-width: 800px) {
  .inventory__centerLeft {
    display: flex;
  }

  .inventory__footer {
    display: none;
  }

  .inventory__center {
    padding-left: 0;
  }
}

@media (min-width: 1400px) {
  .inventory__centerLeft > a {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 1.25rem;
    gap: 0.75rem;
  }

  .inventory__centerLeft > a > span {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .inventory__centerLeft > a > svg {
    font-size: 22px;
  }
}
.dashboard-wrap {
  display: grid;
  width: calc(100% - 250px);
  margin-left: auto;
  column-gap: 30px;
  grid-template-columns: 1fr;

  height: 100%;
}
.wallets {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-height: calc(100vh - 170px);
  overflow-y: auto;
}
.wallets .row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  color: white;
  margin-top: 40px;
}
.wallets .row svg {
  width: 40px;
  height: 40px;
  box-sizing: border-box !important;
  background-color: #4ca2af;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.main-dash {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  background-color: #121429;
  border: 2px solid #4ca2af;
  box-shadow: 0 0.125rem 0.5625rem 0 #4ca2af;
}
.main-dash h1 {
  font-size: 20px;
  font-weight: 700;
  color: white;
}
.main-dash h1 svg {
  font-size: 15px;
  cursor: pointer;
}
.main-dash .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-dash > .row {
  width: 100%;
  gap: 50px;
  margin-top: 30px;
}
.main-dash .row .col {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}
.row .col .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  width: 100%;
}
.col .row p {
  color: white;
  font-size: 16px;
  font-weight: 500;
}
.col .row h3 {
  color: white;
  color: #4ca2af;
  font-weight: 300;
  font-size: 22px;
}
.col .row h3 a {
  color: white;
  font-size: 14px;
  margin-left: 10px;
}
.col .row h4 {
  color: white;
  font-size: 14px;
  font-weight: 400;
}
.dash-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  padding: 20px 0px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.dash-links a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  color: #9b9b9b;
  font-size: 14px;
}
.dash-links .active-dash-link {
  color: #4ca2af;
  text-decoration: none;
}
.dash-links a svg {
  font-size: 20px;
}
.dash-comps {
  width: 100%;
  min-height: 340px;
  padding: 10px 0px;
}
@media screen and (max-width: 1236px) {
  .col .row h3 {
    font-size: 18px;
  }
  .main-dash > .row {
    gap: 20px;
  }
}
@media screen and (max-width: 1186px) {
  .dashboard-wrap {
    grid-template-rows: auto 1fr;
    margin-left: 180px;
    row-gap: 40px;
  }

  .wallets {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    max-width: 100%;
    margin-top: 0px;
    height: auto;
  }
  .wallets .row {
    margin: 0px;
    width: auto;
    margin-left: 20px;
  }
}
@media screen and (max-width: 1064px) {
  .main-dash > .row {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
  }
  .main-dash .row .col {
    gap: 20px;
    width: 100%;
  }
  .col .row h4 {
    font-size: 13px;
  }
}
@media screen and (max-width: 800px) {
  .dashboard-wrap {
    width: 100%;
    margin: 0px;
  }
}
@media screen and (max-width: 532px) {
  .col .row p {
    font-size: 14px;
  }
  .col .row h4 {
    word-break: break-all;
    text-align: right;
  }
  .dash-links {
    width: 100%;
    gap: 4px;
    justify-content: space-between;
  }
  .dash-links a {
    font-size: 12px;
  }
  .dash-links a svg {
    font-size: 14px;
  }
  .token-page h1,
  .nft-page h1,
  .app-page h1 {
    font-size: 16px !important;
  }
  .token-page h2,
  .nft-page h2,
  .app-page h2 {
    text-align: center !important;
  }
}
@media screen and (max-width: 400px) {
  .col .row h3 {
    font-size: 16px;
  }
  .col .row h3 a {
    font-size: 13px;
  }
}
