.masterpin {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.masterpin__header {
  color: white;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 20px 1rem;
}

.masterpin__header > svg {
  cursor: pointer;
}

.masterpin__header > img {
  width: 20px;
}

.masterpin__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 1rem;
  border: 2px solid #4ca2af;
  border-radius: 8px;
}

.masterpin__heading {
  color: #4ca2af;
  font-size: 20px;
}

.masterpin__para {
  color: white;
  font-size: 15px;
  margin-bottom: 2rem;
}

.masterpin__input {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.masterpin__input > span {
  color: white;
  font-size: 14px;
}

.masterpin__input > input {
  height: 40px;
  padding-left: 0.5rem;
  font-size: 15px;
  outline: none;
  background-color: transparent;
  border: 2px solid #4ca2af;
  color: white;
  border-radius: 4px;
}

.masterpin .aqua-btn {
  text-align: center;
  padding-block: 0.6rem;
  border-radius: 4px;
}

.loader {
  text-align: center;
}
