.nft-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.nft-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  text-align: center;
}

.nft-image {
  width: 100%; 
  height: auto;
  max-width: 300px;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
}

.nft-name {
  font-weight: bold;
  color: rgb(67, 195, 195);
}

.nft-token-id {
  color: rgb(61, 224, 224);
}

.mint-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.nftCard {
  border: 0.5px solid rgb(98, 144, 161);
  border-radius: 15px;
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  background-color: #34595f;
  margin-bottom: 10px;
}

.flexButton{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}
