.token-page {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
}
.token-page h1 {
  font-size: 20px;
  font-weight: 700;
  color: white;
}
.token-page h1 span {
  font-weight: 600;
  color: gray;
  font-size: 12px;
  margin-left: 10px;
}
.bottom-token {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/public/assets/bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.bottom-token h2 {
  font-size: 18px;
  color: white;
}

.bottom-token{
    display: flex;
}
