.session {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.session table {
  width: 100%;
  border-collapse: separate;
  table-layout: auto;
}
.session table th {
  text-align: left;
  padding-left: 10px;
  background-color: white;
  height: 40px;
  font-size: 14px;
}
.session table td {
  background-color: #4ca2af;
  height: 50px;
  padding-left: 10px;
  font-size: 14px;
  color: white;
}
.session .aqua-btn {
  width: 200px;
  font-size: 16px;
  border-radius: 10px;
  height: 40px;
}
@media screen and (max-width: 1226px) {
  .session table {
    min-width: 1000px;
  }
  .table-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: scroll;
    max-width: 610px;
  }
}
