.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #121529;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: 30%;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color:'white'
  }
  
  .open {
    display: flex;
  }
  

  .input-div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    max-width: 500px;
    margin: 10px 0px;
  }
  .input-div input {
    width: 100%;
    height: 40px;
    border-radius: 2px;
    font-size: 16px;
    border: 1px solid #4ca2af;
    padding-left: 10px;
    background-color: transparent;
    color: white;
  }
  .input-div input:focus {
    outline: none;
  }
  .input-div label {
    font-size: 14px;
    font-weight: 400;
    color: white;
  }
  .input-div > div input {
    border: none;
  }
  .input-div > div {
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #4ca2af;
  }
  .input-div > div button,
  .input-div > div select {
    min-width: 50px;
    height: 40px;
    border: none;
    cursor: pointer;
    background-color: #4ca2af;
    color: white;
    font-size: 16px;
  }