.container {
  min-height: 50vh;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}

.reg {
  color: black;
  background-color: white;
}

.register {
  width: 100%;
  max-width: 600px;
}

.register__top {
  background-color: transparent;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 10vh;
}

.register__logoGif {
  width: 100%;
}

.img-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.card {
	margin: 0.5rem;
	padding: 0.7rem;
	text-align: center;
	color: #0070f3;
	background-color: #fafafa;
	text-decoration: none;
	border: 1px solid #0070f3;
	border-radius: 10px;
	transition: color 0.15s ease, border-color 0.15s ease;
	width: 100%;
  cursor: pointer;
}



.input-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-width: 500px;
  margin: 10px 0px;
}
.input-div input {
  width: 115%;
  height: 40px;
  border-radius: 2px;
  font-size: 16px;
  border: 1px solid #4ca2af;
  padding-left: 10px;
  background-color: transparent;
  color: white;
}
.input-div input:focus {
  outline: none;
}
.input-div label {
  font-size: 14px;
  font-weight: 400;
  color: white;
}
.input-div > div input {
  border: none;
}
.input-div > div {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #4ca2af;
}
.input-div > div button,
.input-div > div select {
  min-width: 50px;
  height: 40px;
  border: none;
  cursor: pointer;
  background-color: #4ca2af;
  color: white;
  font-size: 16px;
}


.google-signin-button {
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.google-signin-button img {
  margin-right: 8px;
  width: 24px; /* Adjust the size of the Google icon */
  height: 24px;
}

.google-signin-button:hover {
  background-color: #f0f0f0;
}